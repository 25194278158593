<template>
  <div class="parentBox" @click="handleShowCard(0)">
        <el-row class="headercontainer">
            <el-col :span="12" class="imgBox">
                    <img class='logoWhite' src="../assets/imgs/logo-white.png" alt="logo">
                    <img class='logoWord' src="../assets/imgs/logo-word.png" alt="logo">
            </el-col>
             <el-col :span="12" class="textBox">
                <i class="icon-bell" @click="handleToMsg">
                    <img src="../assets/imgs/msg.png" alt="">
                    <b v-if='unRead>0'>{{unRead}}</b>
                </i> 
                <span class="colLine"></span> 
                <span class="username" @click.stop="handleShowCard(1)">{{username}}</span>  
                <i class="el-icon-arrow-down" @click.stop="handleShowCard(1)"></i>
            </el-col>
            <transition
                enter-active-class="animate__animated animate__fadeIn animate__faster" 
                leave-active-class="animate__animated animate__fadeOut"
            >
                <el-card class="box-card" v-show="showCard"  @click.native.stop>
                    <div slot="header" class="clearfix">
                        <div class="bgBox">
                            <img src="../assets/imgs/avbg.png" alt="YG">
                        </div>
                        <div>
                            <p>{{username}}</p>
                            <p>email: {{useremail}}</p>
                        </div>
                    </div>
                    <div class="cancel" @click="handleLogout">
                        退出登录
                    </div>
                </el-card>
            </transition>
        </el-row>
        <div class="maincontainer">
            <div class="main-aside"> 
                <AsideMenu />
            </div>
            <div class="main-content">
                <router-view/>
            </div>
        </div>
  </div>
</template>

<script>
import AsideMenu from './asideMenu'
import {mapActions} from 'vuex'
export default {
    components:{
        AsideMenu
    },
    data() {
        return {
            showCard:false,
        }
    },
    computed: {
        username() {
            return this.$store.getters['user/username']
        },
        unRead(){
            return this.$store.getters['msgCenter/unReadCount']
        },
        useremail(){
            return this.$store.getters['user/useremail']
        }

    },
    methods: {
        handleShowCard(num){
            this.showCard = num ? !this.showCard : false
        },
        handleLogout(){//退出登录
            sessionStorage.clear()
            this.$router.push('/login')
        },
        handleToMsg(){
            if(this.$route.name!=="Msgcenter"){
                this.$router.push({
                    path:'/admin/msgcenter',
                    query:{
                        type:'unread'
                    }
                })
            }else{
                this.$message({
                    showClose: true,
                    message: '您已打开消息中心！',
                    type: 'warning'
                })
            }
            
        },
        ...mapActions({
            reqHomeData:'home/homeDataAct',
            reqReportOptions:'dataReport/reportOptionsAct',
            reqReportData:'dataReport/reportDataAct',
            reqEchartData:'dataReport/reportEchartAct',
            reqMsg:'msgCenter/reqMsgAct'
        })
    },
    created(){
        this.reqHomeData()
        this.reqReportOptions()
        this.reqReportData()
        this.reqEchartData()
        this.reqMsg({
            myproof:false,
            reqArg:{
                page:1,
                pageSize:10,
                type:0,
            }
        })
        sessionStorage.removeItem('groupBy')
        sessionStorage.removeItem('searchDate')
    }
};
</script>

<style lang="less" scoped>
@color-primary:#28A5DC;
.parentBox{
    height:100%;
    .headercontainer{
        height: 50px;
        position: relative;
        background: @color-primary;
        .box-card{
            position: absolute;
            min-width:220px;
            right:2px;
            top:108%;
            z-index: 100;
            /deep/ .el-card__body{
                padding:0;
            }
            .clearfix{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .bgBox{
                    width:50px;
                    height:50px;
                    border: 0.5px solid #ccc;
                    border-radius: 50%;
                    margin-right:18px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                p{
                    font-size: 16px;
                    margin-bottom:3px;
                }
                p:last-child{
                    font-size: 12px;
                    color:#aaa;
                }
            }
            .cancel{
                width: 100%;
                height: 40px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                &:hover{
                    background: #F5F7F9;
                }
            }
        }
        .imgBox{
            height:100%;
            display: flex;
            align-items: center;
            padding:0 20px;
            .logoWhite{
                height:76%;
                margin-right:20px;
            }
            .logoWord{
                height: 45%;
            }
        }
        .textBox{
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: white;
            font-size: 14px;
            .icon-bell{
                cursor: pointer;
                padding: 12px;
                position: relative;
                b{
                    position: absolute;
                    display: inline-block;
                    width: 16px;
                    height:16px;
                    border-radius: 50%;
                    background: red;
                    color: white;
                    font-size: 12px;
                    text-align: center;
                    line-height: 16px;
                    top: 6px;
                    left:23px;
                }
            }
            .colLine{
                width:.5px;
                height: 16px;
                background: #79C3E4;
                margin: 5px;
            }
            .username{
                padding:15px 3px 15px 12px;
                cursor: pointer;
            }
            .el-icon-arrow-down{
                font-size: 10px;
                padding: 15px 0;
                padding-right:20px;
                cursor: pointer;
            }
            
        }
    }
    .maincontainer{
        height: calc(100% - 50px);
        .main-aside{
            height: 100%;
            float: left;
            border-right:1px solid #dde;
            width:162px;
            background: white;
        }
        .main-content{
            height: 100%;
            overflow: auto;
            background: white;
        }
    }
}
</style>